@import "../../common.less";

.image-wrapper {
	position: relative;
	overflow: hidden;
	background-color: fade(black, 10);
	.image {
		.flex-center-align();
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		flex-flow: column;
		color: fade(black, 20);
		z-index: 5;
	}

	.image-mask {
		.image();
		z-index: 10;
	}
}

