@import "../../common.less";

.home-page {
	width: 100%;
	height: 100%;

	.tabs {
		height: 100% !important;
		width: 100%;
		max-width: unset !important;
		margin: 0;
	}

	.ant-tabs-content-holder {
		width: 100%;
		display: flex;
	}

	.frame {
		flex-grow: 1;
		width: 100%;
		height: 100%;
		border: none;
		border: 1px solid fade(black, 20);
		border-radius: 8px;
		scroll-behavior: smooth;
		min-height: 500px;
	}
}

