@import "../../common.less";

.shop-categories-settings-wrapper {
	.add-cat-form {
		margin-bottom: 1rem;
	}

	.list {
		background-color: white;
	}
}

.setting-edit-form {
	.ant-form-item {
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.tabs {
	max-width: 1024px;
	margin: auto;
}

.slider-settings {
    display: grid;
    gap: 16px;
    .xs({grid-template-columns: repeat(1, minmax(0, 1fr));});
    .lg({grid-template-columns: repeat(2, minmax(0, 1fr));});

    .btn-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
    }
}

