@import "../../common.less";

.grid-card {
	width: 100%;
	height: 100%;
	background-color: white;
	min-height: 100px;
	border-radius: 8px;
	box-shadow: 0 0 16px fade(black, 2);
	box-sizing: border-box;
	overflow: hidden;
	transition: all 0.15s ease-out;

	position: relative;
	flex-shrink: 0;
	// -webkit-user-select: none;
	// -webkit-touch-callout: none;
	// pointer-events: none;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			box-shadow: 0 8px 24px fade(black, 10);
			transform: scale(1.02);
		}
	}

	.sold-mask {
		.flex-center-align();
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 16px;
		transform: translate(-50%, -50%);
		background-color: fade(black, 40);
		z-index: 999;
		font-size: 2em;
		border: 2px dashed @pink-light;
		border-radius: 12px;
		color: @pink-light;
		font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
			sans-serif;
		font-style: italic;
		font-weight: bold;
	}

	.content {
		padding: 0.8rem !important;
		color: @text2 !important;
		box-sizing: border-box;

		.brand {
			font-family: Arial, Helvetica, sans-serif;
			font-size: 0.8em;
			text-transform: uppercase;
		}

		.one-line-text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			box-sizing: border-box;
		}

		.name {
			.one-line-text();
			font-size: 0.9em;
		}

		.description {
			.one-line-text();
			font-size: 0.85em;
			color: @text2;
		}

		.tags {
			font-family: Arial, Helvetica, sans-serif;
			width: 100%;
			text-align: right;
			.ant-tag {
				margin-right: 4px;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

.action-btns {
	width: 100%;
	display: grid;
	grid-auto-flow: column;
	margin-top: 0.4rem;
	gap: 0.6rem;
}

.draggable-wrapper {
	width: 100% !important;
	height: 100% !important;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	position: move !important;
}

.quick-edit-btn {
	position: absolute;
	top: 0.4rem;
	right: 0.4rem;
	z-index: 10;
	background-color: fade(black, 30);
	color: white;
}

