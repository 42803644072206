
.expanded-row-container {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1.5fr);
    gap: 10px;
}

.progress-grid {
    width: 100%;
    display: grid !important;
    grid-template-columns: 1fr;
    gap: 8px;
}

.auth-detail-container {
    display: flex;
    flex-flow: row;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
}

.auth-image-container {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.auth-detail-container .auth-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.auth-detail-container .rule-name {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.8em;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
}


.text-msg {
    border-radius: 4px;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.edit-btns-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px
}
