@import "../../common.less";

.create-order-card {
	max-width: 1024px;
	margin: auto;
}

.shop-item-info {
	margin-bottom: 1rem;
	display: grid;
	grid-template-columns: 140px 1fr;
	gap: 0.6rem;
	align-items: center;

	.text-info {
		line-height: 1.6em;
		.brand-name {
			margin-right: 4px;
			text-transform: uppercase;
		}

		.item-name {
		}
	}
}

.final-price-box {
	margin-bottom: 1rem;
}

.user-search-box {
	margin-bottom: 1rem;
}

.user-search-results {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	gap: 0.6rem;
	margin-bottom: 1rem;

	.object-id {
		font-size: 0.8em;
		color: @text2;
	}
}

.selected-user {
	margin-bottom: 1rem;
	padding: 0.2rem 0.6rem;
	border: 1px solid fade(black, 15);
	.flex-center-align();
	justify-content: flex-start;

	.nickName {
		margin-left: 8px;
	}
}

