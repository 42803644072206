@import "../../common.less";

@gap: 0.6rem;
.order-detail-grid {
	display: grid;
	gap: @gap;
	.md({grid-template-columns: 1fr;});
	.lg({grid-template-columns: repeat(2, minmax(0, 1fr));});
	.xl({grid-template-columns: repeat(2, minmax(0, 1fr));});
	max-width: 1024px;
	margin: 0 auto;

	.col {
		gap: @gap;
		width: 100%;
		display: grid;
	}

	.left-col {
		.col();
	}

	.right-col {
		.col();
		.info {
			
		}
	}
}

.image-grid {
	display: grid;
	gap: 0.4rem;
	.xs({grid-template-columns: repeat(2, minmax(0, 1fr))});
	.lg({grid-template-columns: repeat(3, minmax(0, 1fr))});
	.xxl({grid-template-columns: repeat(4, minmax(0, 1fr))});
}

.flex-col-list-item {
	display: grid;
	grid-auto-flow: column;
	.title {
		color: black;
	}
}

.two-col-list-item {
	.flex-col-list-item();
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.price-paragraph {
	&::before {
		content: "¥";
	}
	display: flex;
	flex-flow: row;
	align-items: center;
	margin-bottom: 0 !important;
}

.address-item {
	.flex-center-align();
	justify-content: flex-start;

	.label {
		background-color: fade(black, 10);
		font-size: 0.9em;
		padding: 2px 4px;
		margin-right: 0.5rem;
	}

	.paragraph {
		margin-bottom: 0px !important;
		line-height: 2.4em;
	}
}

.actions-card {
	.btn-grid {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 16px;
		
		button {
			width: 100%;
		}
	}
}

