// Text
@font-size: 26px;
@text: #555555;
@text2: #888888;
@text3: #d8d8d8;
@light-gray: #f7f7f5;

// VI
@pink: #d69f9f;
@pink-saturated: #db7b7b;
@pink-light: #edd2cc;
@pink-dark: #bf8686;

@tiffany-blue: #9fd2d6;
@wx-green: #4caf4f;

// Backgrounds
@bg: white;
@bg2: fade(black, 10);
@bg3: fade(black, 20);
@pink-linear-gradient: linear-gradient(to bottom, @pink, @pink-light);
@pink-linear-gradient-reverse: linear-gradient(to top, @pink, @pink-light);
@pink-linear-gradient-saturated: linear-gradient(
	to bottom,
	@pink-saturated,
	@pink
);
@pink-linear-gradient-reverse-saturated: linear-gradient(
	to top,
	@pink-saturated,
	@pink
);
@pink-radial-gradient: radial-gradient(
	farthest-side at 100% 100%,
	@pink,
	@pink-light
);

.flex-center-align {
	display: flex;
	justify-content: center;
	align-items: center;
}

// Responsive Functions
.responsive(@minWidth, @style) {
	@media only screen and (min-width: @minWidth) {
		@style();
	}
}

.xs (@style) {
	.responsive(0px, {
        @style();
    });
}

.sm (@style) {
	.responsive(576px, {
        @style();
    });
}

.md (@style) {
	.responsive(768px, {
        @style();
    });
}

.lg (@style) {
	.responsive(992px, {
        @style();
    });
}

.xl (@style) {
	.responsive(1200px, {
        @style();
    });
}

.xxl (@style) {
	.responsive(1600px, {
        @style();
    });
}

.xxxl (@style) {
	.responsive(2560px, {
        @style();
    });
}

.xxxxl (@style) {
	.responsive(3200px, {
        @style();
    });
}

.item-grid {
	display: grid;
	@gap: 0.6rem;
	@gapm: 0.8rem;
	@gapl: 1rem;

	.make-grid (@cols, @gap) {
		grid-template-columns: repeat(@cols, minmax(0, 1fr));
		gap: @gap;
	}

	.xs(.make-grid(2, @gap));
	.lg(.make-grid(3, @gapm));
	.xl(.make-grid(4, @gapm));
	.xxl(.make-grid(6, @gapl));
	.xxxl(.make-grid(8, @gapl));
	.xxxxl(.make-grid(12, @gapl));
}
