@import "../../common.less";

.user-basic-info {
	display: flex;
	flex-flow: row;
	align-items: center;

	.avatar {
		width: 32px;
		height: 32px;
		border-radius: 4px;
        margin-right: 4px;
        object-fit: cover;
        cursor: pointer;
	}

	.nick-name-wrapper {
		.nick-name {
		}

		.from {
			font-size: 0.9em;
			color: fade(black, 50);
		}
	}
}
.avatar-large {
	width: 320px !important;
}

