@import "../../common.less";



.money-grid {
    display: grid;
    gap: 16px;
    .xs({grid-template-columns: repeat(1, minmax(0, 1fr));});
    .lg({grid-template-columns: repeat(2, minmax(0, 1fr));});
    .xxl({grid-template-columns: repeat(3, minmax(0, 1fr));});
    .xxxl({grid-template-columns: repeat(4, minmax(0, 1fr));});
}
