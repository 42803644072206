@import "../../common.less";

.drawer {
	// z-index: 9999;
}

.ant-drawer-content-wrapper {
	max-width: 1440px;
	// margin-left: 50%;
	// transform: translate(-50%, 0);

	.ant-drawer-body {
		padding: 1rem 1rem 0 1rem;
		background-color: fade(black, 5);
		box-sizing: border-box;

		.drawer-body-container {
			// max-width: 1440px;
			margin: 0 auto 1rem auto;
			// margin-bottom: 1rem;
			@grid-gap: 0.8rem;
			display: grid;
			gap: @grid-gap;
			.xs({grid-template-columns: 1fr});
			.xl({grid-template-columns: 1fr 0.6fr;});

			.ant-card {
				box-shadow: 0 2px 16px fade(black, 5);
			}

			.left-col {
				display: grid;
				gap: @grid-gap;
			}

			.right-col {
				display: grid;
				gap: @grid-gap;
			}
		}
	}

	.drawer-body-container-mobile {
		grid-template-columns: 1fr;
	}
}

.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 4px 4px 4px 0;
}

.form-btns-wrapper {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-end;
	padding: 0 1rem;
}

.loading-spinner-wrapper {
	.flex-center-align();
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: fade(white, 70);
	z-index: 999;
}

.ant-select-selection-item {
	.user-avatar {
		width: 1.2rem;
		height: 1.2rem;
	}
}

