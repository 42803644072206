@import "../../common.less";


.spinner {
    width: 100%;
    margin: 1rem auto;
}
.stats-grid {
	display: grid;
    gap: 1rem;
    .xs({grid-template-columns: repeat(1, minmax(0, 1fr));});
    .xl({grid-template-columns: repeat(2, minmax(0, 1fr));});

    .stats-content-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;

        .prefix {
            color: yellow;
        }
    }
}

