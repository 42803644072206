@import "../../common.less";

.main-layout {
	min-height: 100%;
	background-color: unset;

	.side-menu {
		height: 100vh;
		background-color: fade(black, 4);
		overflow-y: scroll;
		background-color: white;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	.content-layout {
		background: fade(black, 3);

		.header {
			background: white;
			display: grid;
			grid-template-columns: 0.2fr 1fr 0.2fr;
			height: 2.75rem;
			padding: 0;
			border-bottom: 1px solid fade(black, 10);
			line-height: 1rem;
			z-index: 1;

			.title {
				.flex-center-align();
				text-align: center;
				padding: 0 !important;
			}

			.nav-item {
				padding: 0 !important;
				display: flex;
				align-items: center;
			}

			.left-item {
				.nav-item();
				justify-content: flex-start;
				padding-left: 0.5rem !important;
			}
			.right-item {
				.nav-item();
				justify-content: flex-end;
				padding-right: 0.5rem !important;
			}

			.drawer {
				padding: 0;
			}
		}

		.content {
			padding: 1rem;
			height: 100%;
		}
	}
}

