.spin {
	animation: spin 1.2s linear infinite !important;
}

@keyframes spin {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

