@import "../../common.less";

// .ant-drawer-header {
// 	background-color: @pink-light;

// 	.ant-drawer-title {
// 		color: fade(black, 70);
// 	}
// }
// .ant-drawer-body {
//     background-color: fade(@pink-light, 20);
// }

.ant-upload-drag {
	width: 100%;
	height: 88px !important;
	padding: 0;

	.ant-upload-btn {
		padding: 0 !important;

		.ant-upload-drag-container {
			padding: 0 !important;
		}
	}
}

.shop-item-photos-grid {
	display: grid;
	gap: 1rem;
	margin: 0.6rem 0;

	.xs({grid-template-columns: repeat(2, 1fr);});
	.lg({grid-template-columns: repeat(3, 1fr);});
	.xl({grid-template-columns: repeat(4, 1fr);});
	.xxl({grid-template-columns: repeat(5, 1fr);});

	.card {
		@pad: 4px;
		box-shadow: 0 4px 24px fade(black, 10);
		background-color: white;
		position: relative;
		overflow: hidden;

		.badge {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 20;
			display: flex;
			flex-flow: row;
			justify-content: flex-end;
			grid-template-columns: repeat(2, 1fr);
			gap: @pad;
			padding: @pad;

			.ant-tag {
				margin: 0;
				margin-left: @pad;
			}
		}

		.tool-btn-wrapper {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			margin-top: @pad;

			button {
				padding: 0;
				flex-grow: 1;
			}
		}
	}
}

.ant-skeleton-paragraph {
	margin: 0;
}

