@import "../../common.less";

.brand-grid {
	display: grid;
    gap: 0.6rem;

    .xs({grid-template-columns: repeat(2, minmax(0, 1fr));});
    .md({grid-template-columns: repeat(3, minmax(0, 1fr));});
    .lg({grid-template-columns: repeat(4, minmax(0, 1fr));});
    .xl({grid-template-columns: repeat(6, minmax(0, 1fr));});
    .xxl({grid-template-columns: repeat(8, minmax(0, 1fr));});
    .xxxl({grid-template-columns: repeat(12, minmax(0, 1fr));});
}

