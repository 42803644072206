@import "../../common.less";
@import "../Appraise/Orders.less";

.user-page {
	margin: auto auto;
	display: grid;
	.xs ({grid-template-columns: 1fr;});
	.lg ({grid-template-columns: 360px 1fr;});
	gap: 16px;
}

