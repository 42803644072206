.sell-item-detail {
	.ant-card {
		margin-bottom: 8px;
	}

	.img-grid {
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(6, 1fr);
		@media screen and (max-width: 480px) {
			grid-template-columns: repeat(4, 1fr);
		}
		margin-bottom: 8px;

		img {
			aspect-ratio: 1;
			max-width: 100%;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.shipping-card {
		.ant-card-body {
			overflow-y: scroll;
			max-height: 40vh;
		}
		.shipping-wrapper {
			display: flex;
			flex-flow: column;
		}
	}
}
.img-preview-modal {
	.ant-modal-body,
	.ant-modal-confirm-content,
	.ant-modal-confirm-btns {
		margin: 0;
		padding: 0;
	}
	.ant-modal-confirm-content {
        max-width: 90vw;
		height: 90vh !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.preview-img {
		max-width: 100%;
		max-height: 100%;
	}
}

.sell-item-table-row {
	cursor: pointer;
}
