@import "../../common.less";

.chatlist-container {
	display: grid;
	flex-flow: row;
	gap: 0px;
	height: 100%;
	// max-height: calc(100vh - 80px);

	.chatlist-frame {
		background-color: white;
		// border: 1px solid fade(black, 20);
		overflow-y: scroll;

		.tools {
			padding: 15px;
		}

		.chatlist {
			.list-item {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 12px 12px;
				position: relative;
				max-width: 100% !important;
				cursor: pointer;
				user-select: none;
				&:hover {
					background-color: #f6f6f6;
				}

				.chat-title {
					font-size: 0.9em;
					opacity: 0.7;
					// white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					line-clamp: 1;
					-webkit-line-clamp: 1;
					width: 100%;
					margin-right: 10%;
				}

				.nickName {
					font-weight: bold;
				}

				.float-div {
					position: absolute;
					top: 4px;
					right: 0px;
					.ant-tag {
						padding: 2px 3px;
						line-height: 1em;
						font-size: 0.86em;
					}
				}

				&.active {
					background-color: #f1f1f1;
				}

				.last-msg-at {
					font-size: 0.9em;
					color: fade(black, 30);
				}
			}
		}
	}
}


.chat-window-modal {
	padding: 0;
	box-sizing: border-box;
	overflow: hidden;
	width: 100% !important;	
	@media screen and (min-width: 728px) {
		width: 90% !important;	
		// height: 90% !important;
	}
	

	.ant-modal-content {
		width: 100%;
	}

	.ant-modal-wrap {
		padding: 0 !important;
	}

	.ant-modal-body {
		padding: 0 !important;
		overflow: scroll;
	}
}
