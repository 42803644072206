@import "../../common.less";

.content-editor-container {
    position: relative;

    .editor-wrapper {

    }
    
}
