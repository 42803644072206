@import "../../common.less";
.login-page {
	.flex-center-align();
    height: 100%;

	.login-card {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
		border: none;
		width: 400px;
		max-width: 80vw;
		transform: translateY(-10vh);

		.ant-card-head {
            .flex-center-align();
            color: @text2;
            min-height: 48px;

			.ant-card-head-wrapper {
				padding: 0;
                margin: 0;

				.ant-card-head-title {
					padding: 0;
					.flex-center-align();
				}
			}
		}

		.ant-card-body {
			padding: 1rem;
		}

		.ant-form-item {
			margin-bottom: 1rem;
        }
        
        .login-btn {
            .flex-center-align();
            transition: all 0.2s ease-in-out;
            display: flex !important;
            padding: 0 24px 0px 32px;

            &.loading {
                width: 100%;
            }
        }
	}
}


