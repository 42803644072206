@import "../../common.less";
.tool-bar {
	width: 100%;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;

	.left {
	}

	.right {
	}
}

.pagination {
	margin-top: 1rem;
	width: 100%;
	display: flex;

	align-items: center;
	.xs ({
		justify-content: center;
	});;
	.lg (
		{
			justify-content: flex-end;
		}
	);;
}

.empty {
	min-height: 70vh;
	.flex-center-align();
	flex-flow: column;
	background-color: fade(black, 2);
	margin: 0;
}

