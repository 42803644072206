// @import "../../App.less";
@import "../../common.less";

.current-chat {
	@mobile-min-width: 728px;
	@window-height: 80vh;
	@window-height-mobile: calc(100vh - 72px);

	display: flex;
	flex-flow: row;

	.chat-window-container {
		background-color: white;
		display: grid;
		grid-template-rows: auto 1fr 60px;
		height: @window-height;
		width: 100%;
		
		@media screen and (max-width: @mobile-min-width) {
			height: @window-height-mobile;
		}

		.chat-info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-flow: row;
			padding: 8px;
			overflow-x: auto;
			scroll-behavior: auto;
			border-bottom: 1px solid fade(black, 10);

			.members {
				display: grid;
				grid-auto-flow: column;
				gap: 4px;
			}

			.member-container {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;

				.member-avatar,
				.member-avatar-img {
					width: 40px;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f1f1f1;
					border: 1px solid #f1f1f1;
					border-radius: 50%;

					&.me {
						border: 1px solid blue;
					}
				}

				.nick-name {
					margin-top: 8;
					font-size: 0.8em;
				}
			}
		}

		.message-list {
			background-color: fade(black, 1);
			overflow-y: scroll;
			padding: 8px 8px;
			display: flex;
			flex-flow: column;

			.spacer {
				display: flex;
				height: 100%;
				flex-shrink: 1;
			}

			.load-more-div {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.message-cell {
				display: flex;
				flex-flow: row;
				align-items: flex-start;
				margin-bottom: 16px;

				&.left {
					justify-content: flex-start;
				}
				&.right {
					justify-content: flex-end;
				}

				.avatar {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					margin-right: 8px;
				}

				.content {
					max-width: 60%;
					padding: 4px 6px;
					background-color: white;
					border: 1px solid fade(black, 10);
					border-radius: 4px;

					.message-img {
						max-width: 200px;
						max-height: 200px;
						border-radius: 4px;
					}
				}
			}
		}

		.input-container {
			height: 60px;
			display: grid;
			align-items: center;
			grid-template-columns: 1fr 80px;
			gap: 16px;
			padding: 8px;
			width: 100%;
			background-color: fade(black, 2);
		}
	}

	.chat-regard-item-container {
		@media screen and (max-width: @mobile-min-width) {
			display: none;
		}
		width: 10vw;
		min-width: 300px;
		.ant-card {
			height: @window-height;
			overflow-y: scroll;
			background-color: white;
		}
	}

	.chat-regard-item-modal,
	.mobile-regard-btn {
		@media screen and (min-width: @mobile-min-width) {
			display: none;
		}
	}
}

