@import "../../common.less";

.appraise-orders-wrapper {
	.filter-form {
		margin-bottom: 1rem;
	}

	.orders-table {
		min-width: 1024px;
		max-height: 800px;
		.ant-tag {
			margin: 0;
		}
		.created-at {
			font-size: 0.9em;
			color: @text2;
			cursor: pointer;
		}
	}
}
