@import "../../common.less";

.operation-tools-tabs {
	max-width: 1024px;
    margin: auto;
    
    .card-maker-btn-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        button {
            margin-left: 12px;
        }
    }

	.share-card-maker {
		max-height: 80vh;
		overflow-y: scroll;
		border-radius: 8px;
		margin-top: 8px;

		.check-box {
			display: flex;
			flex-flow: row;
			align-items: center;
			width: 100%;
		}

		.cover {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 4px;
			overflow: hidden;
			margin-right: 4px;
			cursor: pointer;
		}
	}

	.shop-favs-table {
		.cover {
			width: 80px;
			height: 80px;
			object-fit: cover;
			border-radius: 8px;
		}
	}
}

.share-card-grid {
	display: grid;
	gap: 8px;
	.lg({grid-template-columns: repeat(1, minmax(0, 1fr));});
	.md({grid-template-columns: repeat(2, minmax(0, 1fr));});
	.lg({grid-template-columns: repeat(3, minmax(0, 1fr));});
	.xl({grid-template-columns: repeat(4, minmax(0, 1fr));});
	.xxxl({grid-template-columns: repeat(6, minmax(0, 1fr));});

	.share-card-wrapper {
		background-color: fade(black, 5);

		.share-card {
			width: 100%;
		}
	}
}

