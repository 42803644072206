.drag-preview {
    background-color: white;
    opacity: 0.9;
    // backdrop-filter: blur(1px);
    border-radius: 1rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 8px 24px fade(black, 50);

    .item-name {
        flex-grow: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        max-width: 100%;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
