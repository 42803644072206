@import "../../common.less";
@gap: 0.6rem;
.shop-item-detail-grid {
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
	display: grid;
	gap: @gap;
	.lg({grid-template-columns: repeat(2, minmax(0, 1fr));});
	.xxl({grid-template-columns: repeat(2, minmax(0, 1fr));});

	.right-grid {
		display: grid;
		gap: @gap;
	}
}

.flex-grid {
	display: grid;
	grid-auto-flow: column;
	.title {
		color: black;
	}
}

.action-btn {
	border-radius: 0.4rem;

	.mobile-screen {
		width: 100%;
	}
	.large-screen {
		width: 100%;
	}

	.xs(.mobile-screen());
	.lg(.large-screen());
}

.save-btn {
	.action-btn();
}

.publish-btn {
	.action-btn();
}

.action-btn-grid {
	display: grid;
    gap: 0.8rem;
    
    .xs ({grid-template-columns: repeat(1, minmax(0, 1fr));});
    .lg ({grid-template-columns: repeat(2, minmax(0, 1fr));});

	.archive-btn {
		.action-btn();
	}

	.delete-btn {
		.action-btn();
	}
}

.non-btn-wrapper {
    width: 100%;
    height: 40px;
    border: 1px solid fade(black, 20);
    background-color: fade(black, 2);
    border-radius: 0.4rem;
}

