@import "../../common.less";

.ant-tooltip {
	max-width: unset;
}

.mobile-table {
	.ant-table {
		font-size: 0.9em !important;
	}

	.cover {
		width: 48px;
		height: 48px;
		object-fit: cover;
		border-radius: 2px;
	}

	.order-id {
		margin-bottom: 4px !important;
		border-bottom: 1px dashed fade(black, 10);
	}

	.shop-item {
		border-radius: 4px;

		.id {
		}
		.brand {
		}
	}

	.status {
		font-size: 0.85em;
		color: @pink;
	}
}

.pc-table {
	.cover-wrapper {
		width: 100%;
		height: 100%;

		.cover-large {
			width: unset;
			height: unset;
			max-width: 400px !important;
			max-height: 400px !important;
			box-sizing: border-box;
		}

		.cover {
			width: 88px;
			height: 64px;
			object-fit: cover;
			border-radius: 2px;
		}
	}

	.id {
		color: @text2 !important;
	}
	.brand {
	}

	.user-wrapper {
		position: relative;

		.avatar-large {
			max-width: 200px !important;
			max-height: 200px !important;
			object-fit: contain;
		}
	}
}

